import { useCallback } from "react";
import Link from "next/link";
import MainNavbar from "./Navbar/MainNavbar";
import IndexFooter from "./IndexFooter";
import * as Track from "@qoohoo/common/utils/analytics/track";
import { button, pageview } from "@qoohoo/common/constants/analytics";

const currentScreen = pageview[404];

export default function Error404() {
  return (
    <>
      <div className="relative flex flex-col items-center w-full min-h-screen text-white">
        <div className="w-full">
          <MainNavbar currentScreen={currentScreen}/>
        </div>
        <div className="relative flex flex-col items-center w-full px-10 text-center">
          <div className="flex flex-col items-center justify-center mt-28 sm:mt-[85px]">
            <h1 className="text-[28px] sm:text-6xl md:text-[80px] leading-[38px] md:leading-[108px] font-extrabold">
              Oh, we didn't find it
            </h1>
            <p className="text-base leading-[22.4px] mt-[24px] md:leading-7 md:text-xl font-normal">
              The page you're looking for is either lost or doesn't exist.
            </p>
            <img
              className="mt-14 w-[120px] sm:w-[180px]"
              src="/assets/images/error-404.svg"
              alt="can not find"
            />
          </div>

          <div className="mt-14 sm:mt-[72px] mb-24">
            <Link href="/" legacyBehavior>
              <button
                className="font-inter sm:text-lg font-semibold text-[#16191B] py-4 sm:py-[18px] px-12 sm:px-[88px] w-max bg-white rounded-full button-solid-shadow hover:scale-105 active:scale-100 duration-200 transition-all"
                onClick={useCallback(() => {
                  Track.buttonClick(button.go_to_home, {
                    url: "/",
                    current_screen: currentScreen,
                  });
                })}
              >
                Go To Home
              </button>
            </Link>
          </div>
          <img
            src="/assets/icons/smoothcorner3.svg"
            alt="404 Stars"
            className="absolute z-[-1] top-[16%] w-5 hidden sm:block h-5 sm:h-auto sm:w-auto sm:top-8 sm:right-20 lg:right-56 select-none"
          />
          <img
            src="/assets/icons/smoothcorner2.svg"
            alt="404 Stars"
            className="absolute z-[-1] top-20 right-3 w-5 h-5 sm:h-auto sm:w-auto sm:top-80 sm:right-[120px] lg:right-[131px] select-none"
          />
          <img
            src="/assets/icons/smoothcorner1.svg"
            alt="404 Stars"
            className="absolute z-[-1] left-[14px] top-32 lg:top-[296px] lg:left-[90px] max-w-6 max-h-6x select-none"
          />
          <img
            src="/assets/icons/smoothcorner4.svg"
            alt="404 Stars"
            className="absolute z-[-1] h-auto w-auto left-40 lg:top-[107px] lg:left-[215px] max-w-6 max-h-6x hidden sm:block select-none"
          />

          <img
            src="/assets/icons/smoothcorner3.svg"
            alt="404 Stars"
            className="absolute z-[-1] top-7 left-[89px] w-5 h-5 sm:hidden select-none"
          />
        </div>

        <img
          src="/assets/images/wave-misc.svg"
          alt="Wave illustration in 404"
          className="hidden w-full select-none sm:block"
        />
        <img
          src="/assets/images/wave-misc-mob.svg"
          alt="Wave illustration in 404"
          className="w-full select-none sm:hidden"
        />
        <div className="w-full">
          <IndexFooter />
        </div>
      </div>
    </>
  );
}
