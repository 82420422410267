import Link from "next/link";
import { useCallback, useEffect } from "react";
import useHasHydrated from "@qoohoo/common/hooks/useHasHydrated";
import * as Track from "@qoohoo/common/utils/analytics/track";
import { button, link, pageview } from "@qoohoo/common/constants/analytics";

export default function Error500(props) {
  const pageUrl = useHasHydrated() && window.location.href;
  const encodedErrorUrl = encodeURIComponent(props.url || pageUrl);
  const mailToLink = `mailto:support@qoohoo.in?subject=Reporting%20a%20broken%20page&body=Hi%20qoohoo%20team%2C%0A%0AI%20noticed%20an%20error%20on%20the%20page%20${encodedErrorUrl}%20with%20code%20${
    props.statusCode || 500
  }.%20Please%20look%20into%20it.%0A%0ARegards%2C%0AGood%20Folk`;

  useEffect(() => {
    Track.pageView(pageview[500]);
  }, []);

  return (
    <>
      <div className="relative flex flex-col items-center w-full min-h-screen text-white">
        <div className="w-full">
          <header className="relative px-5 pt-6 sm:px-8 md:px-12 lg:px-20 md:py-9 font-nunito">
            <nav className="flex items-center justify-between">
              <Link
                href="/"
                onClick={useCallback(() => {
                  Track.linkClick(link.qoohoo_logo, {
                    url: "/",
                    current_screen: pageview[500],
                  });
                })}
              >
                <img
                  src="/assets/images/qoohoo-logo-white.svg"
                  alt="qoohoo Logo"
                  className="w-[120px] h-[32px] min-w-[120px] min-h-[32px] lg:h-auto lg:w-auto select-none"
                />
              </Link>
            </nav>
          </header>
        </div>

        <div className="relative flex flex-col items-center w-full px-10 text-center">
          <div className="flex flex-col items-center justify-center mt-28 sm:mt-[85px]">
            <h1 className="text-[28px] sm:text-6xl md:text-[80px] leading-[38px] md:leading-[108px] font-extrabold">
              Poocho unplugged it!
            </h1>
            <p className="text-xl leading-[22px] mt-[24px] md:text-xl font-normal">
              We'll be back shortly. Thank you for your patience.
            </p>
            <img
              className="mt-14 w-[120px] sm:w-[180px]"
              src="/assets/images/error-500.svg"
              alt="can not find"
            />
          </div>

          <div className="mt-14 sm:mt-[72px] mb-24 flex flex-col font-inter">
            <Link
              href="/"
              className="sm:text-lg font-semibold text-[#16191B] py-4 sm:py-[18px] px-12 sm:px-[88px] w-max bg-white rounded-full button-solid-shadow hover:scale-105 active:scale-100 duration-200 transition-all"
              onClick={useCallback(() => {
                Track.buttonClick(button.go_to_home, {
                  url: "/",
                  current_screen: pageview[500],
                });
              })}
            >
              Go To Home
            </Link>

            <div className="mt-10">
              <Link
                href={mailToLink}
                className="text-base font-normal underline sm:text-lg text-[#B8B8B8] hover:text-white"
                onClick={useCallback(() => {
                  Track.linkClick(link.support, {
                    url: "mailto:support@qoohoo.in?subject=Report-page-breaking",
                    current_screen: pageview[500],
                  });
                })}
              >
                Contact Support
              </Link>
            </div>
          </div>
          <img
            src="/assets/icons/smoothcorner3.svg"
            alt="404 Stars"
            className="absolute z-[-1] top-[16%] w-5 hidden sm:block h-5 sm:h-auto sm:w-auto sm:top-8 sm:right-20 lg:right-56 select-none"
          />
          <img
            src="/assets/icons/smoothcorner2.svg"
            alt="404 Stars"
            className="absolute z-[-1] top-20 right-3 w-5 h-5 sm:h-auto sm:w-auto sm:top-80 sm:right-[120px] lg:right-[131px] select-none"
          />
          <img
            src="/assets/icons/smoothcorner1.svg"
            alt="404 Stars"
            className="absolute z-[-1] left-[14px] top-32 lg:top-[296px] lg:left-[90px] max-w-6 max-h-6x select-none"
          />
          <img
            src="/assets/icons/smoothcorner4.svg"
            alt="404 Stars"
            className="absolute z-[-1] h-auto w-auto left-40 lg:top-[107px] lg:left-[215px] max-w-6 max-h-6x hidden sm:block select-none"
          />

          <img
            src="/assets/icons/smoothcorner3.svg"
            alt="404 Stars"
            className="absolute z-[-1] top-7 left-[89px] w-5 h-5 sm:hidden select-none"
          />
        </div>
      </div>
    </>
  );
}
